@import '~bloko/common/styles/colors';

.deep-links {
    display: inline-block;
}

.deep-link {
    display: inline-flex;
    align-items: center;

    margin-right: 16px;

    white-space: nowrap;
    cursor: pointer;
}

.deep-link_whatsapp {
    &:hover {
        color: @color-green-40;
    }
}

.deep-link_viber {
    &:hover {
        color: @color-violet-70;
    }
}

.deep-link_telegram {
    &:hover {
        color: @color-blue-50;
    }
}

.tag {
    cursor: pointer;
}
