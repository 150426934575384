@import '@hh.ru/magritte-ui/breakpoints';

.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .screen-gt-xs({
        flex-direction: row;
        justify-content: space-between;
    });
}

.title {
    overflow-wrap: anywhere;
}

.salary {
    flex-shrink: 0;
}
