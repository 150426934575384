@import '@hh.ru/magritte-ui/breakpoints';

.content-wrapper {
    display: flex;
    padding: 12px;
    gap: 24px;
    flex-direction: column;
    align-items: center;

    .screen-gt-s({
        flex-direction: row;
        align-items: flex-start;
    });
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;

    .screen-gt-s({
        align-items: center;
        justify-content: space-between;
        gap: 12px;
    });
}

.video-link-wrapper {
    flex-shrink: 0;
}

.video-link-wrapper-mobile {
    margin: 12px 0 0;
}

.text {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .screen-gt-s({
        align-items: flex-start;
        text-align: left;
    });
}

.tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;

    .screen-gt-s({
        justify-content: flex-start;
    });
}
