.phone-container {
    display: flex;
    align-items: center;
    gap: 4px;
    flex-wrap: wrap;
}

.comment {
    display: flex;
    align-items: center;
    gap: 4px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    flex-shrink: 0;
}
