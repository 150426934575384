.drop-content {
    width: 252px;
}

.folders-container {
    max-height: 200px;
    overflow: auto;
}

.new-folder-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}
